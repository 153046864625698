.body {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background: linear-gradient(var(--pink) 0%, var(--violet) 100%);
}



.wrapper {
    width: 380px;
    padding: 25px 30px;
    overflow: hidden;
    position: relative;
    border-radius: 15px;
    background: var(--white);
    box-shadow: 0px 6px 15px var(--lightbshadow);
}

.imgarea {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 380px;
    overflow: auto;
    margin-top: 25px;
    margin-bottom: 25px;
    border-radius: 15px;
    box-shadow: 0px 6px 12px var(--lightbshadow);
}

form {
    width: 100%;
    margin: 0 auto;
    padding-bottom: 0.1em;
}

label {
    display: block;
    color: var(--lightblack);
    padding: 5px 0 5px;
}

input,textarea {
    border-radius: 5px;
    border: 1px solid var(--lightblack);
    width: 75%;
    min-height: 2em;
    box-shadow: 0px 6px 12px var(--lightbshadow);
    padding-left: 10px;
}

span {
    margin-top: 15px;
    color: var(--lightblack);
    text-align: center;
    font-size: 18px;
}
label, input, textarea {
    margin: 0 0 0 12%;
    max-width: 75%;
}

button[type="submit"] {
    display: block;
    width: 25%;
    margin: 1.4em auto;
    height: 2em;
    font-size: 1rem;
    border-color: var(--white);
    border-radius: 5px;
    background: linear-gradient(var(--pink) 0%, var(--violet) 100%);
    color: var(--white);
    cursor: pointer;
    box-shadow: 0px 6px 12px var(--lightbshadow);
    padding-top: 3px;
}
