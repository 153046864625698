import React, { useState, useEffect } from "react";
import * as style from "./Navbar.module.css";
import { MdQueueMusic, MdOutlineRepeat } from "react-icons/md";
import { BsFillPlayFill } from "react-icons/bs";
import { RxTrackPrevious, RxTrackNext } from "react-icons/rx";
import { useNavigate, useLocation } from "react-router-dom";

const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [activeIcon, setActiveIcon] = useState("");
  const [currentTime, setCurrentTime] = useState("0:00");
  const [progressWidth, setProgressWidth] = useState(0);

  const handleHomeClick = () => {
    navigate("/");
  };

  const handleSkillsClick = () => {
    navigate("/Skills");
  };

  const handleAboutClick = () => {
    navigate("/About");
  };

  const contact = () => {
    navigate("/Contact");
  };

  const handleProjectList = () => {
    navigate("/ProjectList");
  };

  useEffect(() => {
    const progressMap = {
      "/": { width: 55, time: "1:60" },
      "/Skills": { width: 77, time: "2:20" },
      "/About": { width: 30, time: "1:10" },
      "/Contact": { width: 12, time: "00:30" },
      "/ProjectList": { width: 100, time: "3:15" },
    };

    const { width, time } = progressMap[location.pathname] || {
      width: 0,
      time: "0:00",
    };
    setCurrentTime(time);
    setProgressWidth(width);

    const progressBarElement = document.querySelector(`.${style.progressbar}`);
    progressBarElement.style.width = "0";
    setTimeout(() => {
      progressBarElement.style.width = `${width}%`;
    }, 0);
  }, [location.pathname]);

  return (
    <div>
      <div className={style.progressarea}>
        <div
          className={style.progressbar}
          style={{ width: `${progressWidth}%` }}
        ></div>
        <div className={style.songtimer}>
          <span className={style.currentime}>{currentTime}</span>
          <span className={style.maxduration}>3:20</span>
        </div>
      </div>
      <div className={style.controls}>
        <MdOutlineRepeat
          className={`${style.icon} ${
            activeIcon === "contact" ? style.active : ""
          }`}
          onClick={contact}
          title="Contact"
        />
        <RxTrackPrevious
          className={`${style.icon} ${
            activeIcon === "about" ? style.active : ""
          }`}
          onClick={handleAboutClick}
          title="About Me"
        />
        <div className={style.playpause}>
          <BsFillPlayFill
            className={`${style.icon} ${style.materialicon} ${style.play} ${
              activeIcon === "home" ? style.active : ""
            }`}
            onClick={handleHomeClick}
            title="Home"
          />
        </div>
        <RxTrackNext
          className={`${style.icon} ${style.materialicon} ${style.next} ${
            activeIcon === "skills" ? style.active : ""
          }`}
          onClick={handleSkillsClick}
          title="My Skills"
        />
        <MdQueueMusic
          className={`${style.icon} ${activeIcon === "ProjectList" ? style.active : ""}`}
          onClick={handleProjectList}
          title="ProjectList"
        />
      </div>
    </div>
  );
};

export default Navbar;
