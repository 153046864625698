import React from 'react';
import * as style from "./Register.module.css";
import {Link} from 'react-router-dom';
import {MdOutlineAddAPhoto} from 'react-icons/md'; 

const Register =()=> {

    return (
      <div className={style.form_container}>
        <div className={style.formwrapper}>
          <h1 className={style.logo}> Chat </h1>
          <span className={style.title}> Register </span>
          <form className={style.form}>
            <input className={style.input} type="text" placeholder="Name" />
            <input className={style.input} type="email" placeholder="Email" />
            <input className={style.input} type="password" placeholder="Password" />
            <input className={style.file} style={{display:"none"}} type="file" />
            <label className={style.label} htmlFor='file' id="file"/>
             <MdOutlineAddAPhoto className={style.avatar} />
                <span className={style.avatartext}> Add an avatar </span>
            <button className={style.button}> Sign Up </button>
          </form>
          <p className={style.paragraph}> Do you have an account? <Link to ="/Login">Login</Link></p>
        </div>
      </div>
    );
};

export default Register;