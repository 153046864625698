import React from 'react'
import style from './Input.module.css';
import {BsPaperclip} from 'react-icons/bs'
import {AiOutlinePicture} from 'react-icons/ai'

const Input = () => {
  return (
    <div className={style.input}>
      <input
        type="text"
        placeholder="Type Something..."
        className={style.messageInput}
      />
      <div className={style.send}>
        <BsPaperclip className={style.icon} />
        <input type="file" style={{ display: "none" }} className={style.file} />
        <label htmlFor="file">
          <AiOutlinePicture className={style.icon2} />
        </label>
        <button className={style.button}> Send </button>
      </div>
    </div>
  );
}

export default Input