import React from "react";
import * as style from "./Login.module.css";
import {Link} from 'react-router-dom';

const Login = () => {

  return (
    <div className={style.form_container}>
      <div className={style.formwrapper}>
        <h1 className={style.logo}> Chat </h1>
        <span className={style.title}> Login </span>
        <form className={style.form}>
          <input className={style.input} type="email" placeholder="Email" />
          <input
            className={style.input}
            type="password"
            placeholder="Password"
          />
          <button className={style.button}> Sign In </button>
        </form>
        <p className={style.paragraph}>
          Don't you have an account? <Link to="/Register"> Register </Link>
        </p>
      </div>
    </div>
  );
};

export default Login;
