.topbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.icon {
    font-size: 30px;
    color: var(--lightblack);
}

.icon:first-child {
    margin-left: -7px;
}

span {
    font-size: 18px;
    margin-left: 3px;
    color: var(--lightblack);
}


