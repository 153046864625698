.body{
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background: linear-gradient(var(--pink) 0%, var(--violet) 100%);
}

.wrapper {
    width: 380px;
    padding: 15px 30px;
    overflow: hidden;
    position: relative;
    border-radius: 15px;
    background: var(--white);
    box-shadow: 0px 6px 15px var(--lightbshadow);
}

.imgarea {
    width: 100%;
    height: 350px;
    overflow: hidden;
    margin-top: 15px;
    border-radius: 15px;
    box-shadow: 0px 6px 12px var(--lightbshadow);
}

.imgarea img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.songdetails {
    text-align: center;
    margin: 20px 0;
}

.songdetails p {
    color: var(--lightblack);
    margin-bottom: 10px;
}

.icon {
    cursor: pointer;
    color: var(--lightblack);
}

.play {
    margin-left: 7px;
    color: white;
}
.facebook,.instagram,.linkedin {
    font-size: 40px;
}
