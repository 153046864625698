.form_container {
    background-color: var(--lightblack);
    margin: 20px;
    height: 65vh;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    flex-direction: column;
}

.formwrapper {
    background-color: rgb(143, 165, 188);
    margin: 30px;
    border-radius: 8px;
    flex-direction: column;
    gap: 10px;
    text-align: center;
}

.logo {
    color: white;
    font-size: 24px;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 5px;
}

.title {
    color: white;
}

.form {
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.button {
    margin: 15px 31px;
    height: 30px;
    background-color: var(--lightblack);
    color: var(--white);
    border-radius: 5px;
    border: none;
    font-size: 18px;
    cursor: pointer;
}

.paragraph {
    text-align: center;
    color: var(--white);
    padding-top: 3px;
    padding-bottom: 20px;
}

.input,
.file {
    padding: 12px;
    border: none;
    width: 300px;
}
a:visited {
    color:var(--white);
}