import React from "react";
import "../style.css";
import { createRoot } from "react-dom/client";
import { App } from "./App.jsx";
import dotenv from "dotenv";

dotenv.config(); // Ladda miljövariabler från .env

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<App />);
