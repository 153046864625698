.userChat {
    padding: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}
.userChatInfo .p {
    font-size: 14px;
    color: lightgray;
    margin-left: 5px;
}
.userChatInfo .span{
    font-size: 18px;
    color: white;
    font-weight: 500;
}
.img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 2px solid white;
    object-fit: cover;
}
.userChat:hover {
    background-color: #868F98;
}