.progressarea .songtimer,
.controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.controls {
    margin: 40px 0 5px 0;
}

.controls .icon {
    font-size: 30px;
    user-select: none;
    background: linear-gradient(var(--pink) 0%, var(--violet) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.controls icon:nth-child(2),
.controls icon:nth-child(4) {
    font-size: 43px;
}

.controls .prev {
    margin-right: -13px;
}

.controls .next {
    margin-left: -13px;
}

.controls .playpause {
    height: 54px;
    width: 54px;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: white;
    background: linear-gradient(var(--white) 0%, var(--darkwhite) 100%);
    box-shadow: 0px 0px 5px var(--pink);
}

.playpause::before {
    position: absolute;
    content: "";
    height: 43px;
    width: 43px;
    border-radius: inherit;
    background: linear-gradient(var(--pink) 0%, var(--violet) 100%);
}

.playpause .icon {
    height: 43px;
    width: 43px;
    line-height: 43px;
    text-align: center;
    background: inherit;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: absolute;
}

.icon {
    cursor: pointer;
    color: var(--lightblack);
}

.play {
    margin-left: 7px;
    color: white;
}

.progressarea {
    height: 6px;
    width: 100%;
    border-radius: 50px;
    background: #f0f0f0;
    cursor: pointer;
}

.progressarea .progressbar {
    height: inherit;
    width: 0%;
    position: relative;
    border-radius: inherit;
    background: linear-gradient(90deg, var(--pink) 0%, var(--violet) 100%);
}


.progressbar::before {
    content: "";
    position: absolute;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    top: 50%;
    right: -5px;
    z-index: 2;
    opacity: 0;
    pointer-events: none;
    transform: translateY(-50%);
    background: inherit;
    transition: opacity 0.2s ease;
}

.progressarea:hover .progressbar::before {
    opacity: 1;
    pointer-events: auto;
}

.progressarea .songtimer {
    margin-top: 2px;
}

.songtimer span {
    font-size: 13px;
    color: var(--lightblack);
}
.active .progressbar {
    width: 100%;
    transition: 1s ease;
        transition-delay: 0.5s
}

.active .currentime {
    color: var(--pink);
    /* Uppdatera färgen efter behov */
}

.progressbar {
    background-color: blue;
    height: 10px;
    transition: width 0.5s ease-in-out;
}

.progressarea {
    background-color: lightgrey;
    height: 10px;
    width: 100%;
}

.songtimer {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
}

.currentime,
.maxduration {
    font-size: 12px;
}



.active {
    color: red;
}

:export {
    materialicon: materialicon;
}