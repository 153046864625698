import React from 'react'
import style from './Search.module.css';
import selfie from "../../Frontend/Img/selfie.png";
import {ImSearch} from 'react-icons/im'

function Search() {
  return (
    <div className={style.search}>
      <div className={style.searchForm}>
        <ImSearch className={style.icon} />
        <input className={style.input} type="text" placeholder="Find a user" />
      </div>
      <div className={style.userChat}>
        <img src={selfie} alt="Sara Rados" className={style.img}></img>
        <div className={style.userChatInfo}>
          <span className={style.span}> Sara </span>
        </div>
      </div>
    </div>
  );
}

export default Search