import React from 'react'
import style from './Message.module.css'

const Message = () => {
  return (
    <div className={style.message}>
      <div className={style.messageInfo}></div>
      <div className={style.messageContent}></div>
    </div>
  );
}

export default Message