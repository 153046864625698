const { initializeApp } = require("firebase/app");
const { getDatabase } = require("firebase/database");

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyALWT-1SB4gCB5HJ7biruOWBsnuEB7F8d8",
  authDomain: "clickedheart.firebaseapp.com",
  projectId: "clickedheart",
  storageBucket: "clickedheart.appspot.com",
  messagingSenderId: "183319176500",
  appId: "1:183319176500:web:d0856b21d1c4f693cdb161",
  measurementId: "G-D3RJXRDG12",
  databaseURL: "https://clickedheart-default-rtdb.europe-west1.firebasedatabase.app"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

module.exports = { app, database };
