import React from 'react'
import Message from './Message.jsx';
import style from './Messages.module.css'

const Messages = () => {
  return (
    <div className={style.messages}>
      <Message />
      <Message />
      <Message />
      <Message />
      <Message />
    </div>
  );
}

export default Messages