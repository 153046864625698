.body {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background: linear-gradient(var(--pink) 0%, var(--violet) 100%);
}

.wrapper {
    width: 380px;
    padding: 25px 30px;
    overflow: hidden;
    position: relative;
    border-radius: 15px;
    background: var(--white);
    box-shadow: 0px 6px 15px var(--lightbshadow);
}

.imgarea {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 380px;
    overflow: auto;
    margin-top: 25px;
    margin-bottom: 25px;
    border-radius: 15px;
    box-shadow: 0px 6px 12px var(--lightbshadow);
}

.imgarea img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
    margin: 15px;
}
.button {
    display: block;
    margin: 15px 0;
    height: 2.5em;
    font-size: 1rem;
    border-color: var(--white);
    border-radius: 5px;
    background: linear-gradient(var(--pink) 0%, var(--violet) 100%);
    color: var(--white);
    cursor: pointer;
    box-shadow: 0px 6px 12px var(--lightbshadow);
    padding: 0.8em 2.8em 2.2em;
    position: relative;
}
.span {
    margin-top: 5px;
    color: var(--lightblack);
    text-align: center;
    font-size: 18px;
    position: relative;
    top: 5px;
}
.p {
    margin: 0 18px;
    font-size: 18px;
    color: var(--lightblack);
    text-align: justify;
}