.search {
    border-bottom: 2px solid white;
}
.searchForm {
    padding: 10px;
}
.input {
    background-color: transparent;
    box-shadow: none;
    color: white;
    outline:none;
    margin-left: 3px;
}
.input::placeholder {
    color: white;
}
.span {
    color: white;
}
.img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 2px solid white;
    object-fit: cover;
}
.userChat {
    padding: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor:pointer;
}
.userChat:hover {
    background-color: #868F98;
}
.icon {
    color: var(--white);
    font-size: 15px;
}
