import React, { useState, useEffect } from "react";
import * as style from "./ProjectList.module.css";
import Topbar from "../Topbar/Topbar";
import Navbar from "../Navbar/Navbar.jsx";

const ProjectList = () => {

    const RandomQuoteGenerator = () => {
      window.open(
        "https://sararados.github.io/Projects//javascript/Random%20Quote%20Generator/index.html",
        "_blank"
      );
    };

    const weatherApp = () => {
      window.open(
        "https://sararados.github.io/WeatherApp/",
        "_blank"
      );
    };

    const Blog  = () => {
      window.open(
        "https://blog.sararados.se",
        "_blank"
      );
    };


  return (
    <div className={style.body}>
      <div className={style.wrapper}>
        <Topbar />
        <div className={style.imgarea}>
          <span className={style.span}> My projects </span>

          <button className={style.button} onClick={RandomQuoteGenerator}>
            Random Quote Generator
          </button>
          <button className={style.button} onClick={weatherApp}>
            Weather App
          </button>
          <button className={style.button} onClick={Blog}>
            My Blog 
            ( A MERN project)
          </button>
        </div>
        <Navbar />
      </div>
    </div>
  );
};

export default ProjectList;
