.body {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background: linear-gradient(var(--pink) 0%, var(--violet) 100%);
}
.wrapper {

    width: 380px;
    padding: 25px 30px;
    overflow: hidden;
    position: relative;
    border-radius: 15px;
    background: var(--white);
    box-shadow: 0px 6px 15px var(--lightbshadow);
}

.imgarea {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 350px;
    overflow: hidden;
    margin-top: 25px;
    margin-bottom:25px;
    border-radius: 15px;
    box-shadow: 0px 6px 12px var(--lightbshadow);
}

.playpause .icon {
    height: 43px;
    width: 43px;
    line-height: 43px;
    text-align: center;
    background: inherit;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: absolute;
}

.icon {
    cursor: pointer;
    color: var(--lightblack);
}

.play {
    margin-left: 7px;
    color:white;
}
.html, .css, .js, .github, .react, .vue, .csharp, .bootstrap {
    font-size: 55px;
    color: var(--lightblack);
}
.iconBox {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90px;
    margin: 30px 5px;
    padding: 2px;
    border: 1px solid var(--lightblack);
    border-radius: 5px;
    background: linear-gradient(var(--white) 0%, var(--darkwhite) 100%);
    box-shadow: 0px 0px 5px var(--pink);
}
.span {
   font-size: 20px;
   color: var(--lightblack);
}
.textContainer {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
}

:export {
    iconContainer: iconContainer;
}