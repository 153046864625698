import React from "react";
import style from "./Navbar.module.css";
import selfie from "../../Frontend/Img/selfie.png";


const Navbar = () => {

  return (
    <div className={style.navbar}>
      <span className={style.logo}> Chat </span>
      <div className={style.user}>
        <img className={style.img}
              src={selfie}
              alt="Sara Rados"
            ></img>
        <span className={style.span}> Sara Rados </span>
        <button className={style.button}> LogOut </button>
      </div>
    </div>
  )
};
export default Navbar;
