import React from 'react'
import style from './Chats.module.css';
import selfie from "../../Frontend/Img/selfie.png";


const Chats = () => {
  return (
    <div className={style.chats}>
      <div className={style.userChat}>
        <img src={selfie} alt="Sara Rados" className={style.img}></img>
        <div className={style.userChatInfo}>
          <span className={style.span}> Sara </span>
          <p className={style.p}> Hello </p>
        </div>
      </div>
      <div className={style.userChat}>
        <img src={selfie} alt="Sara Rados" className={style.img}></img>
        <div className={style.userChatInfo}>
          <span className={style.span}> Sara </span>
          <p className={style.p}> Hello </p>
        </div>
      </div>
      <div className={style.userChat}>
        <img src={selfie} alt="Sara Rados" className={style.img}></img>
        <div className={style.userChatInfo}>
          <span className={style.span}> Sara </span>
          <p className={style.p}> Hello </p>
        </div>
      </div>
    </div>
  );
}

export default Chats