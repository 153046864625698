.navbar {
    display: flex;
    align-items: center;
    background-color: black;
    height: 50px;
    justify-content: space-between;
    color: var(--lightblack);
}
.logo {
    font-weight: bold;
    margin-top: -2px;
    color: var(--white);
    font-size: 24px;
    flex-shrink: 0;
    margin-left: 20px;
}
@media screen and (max-width: 1024){
    .logo {
        display: none;
    }
    .button {
        position: absolute;
        bottom: 10px;
        margin-left: 55%;
    }
    .img {
        margin-left: 15px;
    }
}
.user {
    display: flex;
    align-items: center;
    gap: 10px;
}
.img {
    background-color: aliceblue;
    height: 35px;
    width: 35px;
    border-radius: 50%;
    object-fit: cover;
}
.button {
    background-color: var(--white);
    border:none;
    border-radius: 5px;
    cursor: pointer;
    padding: 5px 8px;
    margin-right: 10px;
}
.span {
    color: var(--white);
    margin-top: -2px;
}
