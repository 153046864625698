import React, { useState } from "react";
import * as style from "./Contact.module.css"
import Topbar from "../Topbar/Topbar.jsx";
import Navbar from "../Navbar/Navbar.jsx";
import emailjs from "emailjs-com";

const ContactForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

   setName("");
   setEmail("");
   setPhone("");
   setMessage("");


    emailjs
      .sendForm(
        "service_195sc9p",
        "template_17bnfe7",
        e.target,
        "aHmapTSXkRKeRJeiY"
      )
      .then(
        (result) => {
          console.log(result.text);
    e.target.reset();
},
(error) => {
      console.log(error.text);
        }
      );
};


  return (
    <div className={style.body}>
    <div className={style.wrapper}>
      <Topbar />
      <div className={style.imgarea}>
        <span> Contact </span>
        <form onSubmit={handleSubmit}>
          <label htmlFor="name"> Name: </label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            id="name"
            name="sender_name"
            required
          />
          <label htmlFor="email"> E-mail: </label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            id="email"
            name="sender_email"
            required
          />
          <label htmlFor="phone"> Phone: </label>
          <input
            type="number"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            pattern="[0-9+]*"
            id="phone"
            name="sender_phonenumber"
          />
          <label htmlFor="message"> Message: </label>
          <textarea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            id="message"
            name="message"
          ></textarea>

          <button type="submit"> Send </button>
        </form>
      </div>
      <Navbar />
    </div>
    </div>
  );
};

export default ContactForm;
