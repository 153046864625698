import React, { useState, useEffect } from "react";
import * as style from "./Topbar.module.css";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { ref, onValue, off, runTransaction } from "firebase/database";
import { database } from "../../../Backend/firebase.js"; // Importera din Firebase-konfiguration
import { useNavigate} from "react-router-dom";


const Topbar = () => {
  const navigate = useNavigate();
  const [isLiked, setIsLiked] = useState(false);
  const [heartClicks, setHeartClicks] = useState(0);

  useEffect(() => {
    const heartClicksRef = ref(database, "heartClicks");
    const unsubscribe = onValue(heartClicksRef, (snapshot) => {
      const clicks = snapshot.val();
      setHeartClicks(clicks);
      localStorage.setItem("heartClicks", clicks); // Spara värdet i localStorage
    });

    // Återställ värdet från localStorage när komponenten monteras
    const cachedHeartClicks = localStorage.getItem("heartClicks");
    if (cachedHeartClicks !== null) {
      setHeartClicks(parseInt(cachedHeartClicks));
    }

    return () => {
      // Avsluta lyssnaren när komponenten avmonteras
      unsubscribe();
    };
  }, []);

  const updateHeartClicks = () => {
    const heartClicksRef = ref(database, "heartClicks");
    runTransaction(heartClicksRef, (currentClicks) => {
      return (currentClicks || 0) + 1;
    });
  };

  const handleHeartClick = () => {
    if (localStorage.getItem("heartClicked")) {
      return; // Ignorera klickhändelsen om användaren redan har klickat
    }

    updateHeartClicks();
    setIsLiked(true);
    localStorage.setItem("heartClicked", "true"); // Markera att användaren har klickat
  };

  // const handleChat = () => {
  //   navigate("/CHatHome");
  // }

  return (
    <div
      className={style.topbar}
      style={{ alignItems: "flex-start", flexWrap: "wrap" }}
    >
      <span onClick={handleHeartClick}>{isLiked ? "❤️" : "🤍"}</span>
      <span style={{ display: "flex", alignItems: "center" }}>
        ({heartClicks})
      </span>
      <span style={{ flexGrow: 1, textAlign: "center" }}> WebDeveloper </span>
      <BiDotsHorizontalRounded
        className={style.icon}
        style={{ marginTop: "15px" }}
        //onClick={handleChat}
        title="Chat"
      />
    </div>
  );
};

export default Topbar;
