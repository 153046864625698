.form_container {
    background-color: var(--lightblack);
    margin: 20px;
    height: 85vh;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius:8px;
    flex-direction: column;
}
.formwrapper {
    background-color: rgb(143, 165, 188);
    margin: 30px;
    border-radius: 8px;
    flex-direction: column;
    gap: 10px;
    text-align: center;
}
.logo {
    color:white;
    font-size: 24px;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 5px;
}
.title {
    color: white;
}
.form {
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.file {
    background-color: var(--white);
}

.button {
    margin: 15px 31px;
    height: 30px;
    background-color: var(--lightblack);
    color: var(--white);
    border-radius: 5px;
    border: none;
    font-size: 18px;
    cursor: pointer;
}
.paragraph {
    text-align: center;
    color: var(--white);
    padding-top: 3px;
    padding-bottom: 20px;
}
.input, .file {
    padding: 12px;
    border: none;
    width: 300px;
}

.label {
  display: flex; 
  align-items: center;
  gap: 10px;
  color: var(--white);
  cursor: pointer;
}
.avatar {
    height: 50px;
    width: 50px;
    margin: -15px 30px;
    color: var(--white);
    cursor: pointer;
}
.avatartext {
    display: inline-block;
    margin-top: -35px;
    margin-left: 5px;
    cursor: pointer;
}
a:visited {
   color: var(--white);

}