import React, { useState, useEffect } from "react";
import * as style from "./Skills.module.css";
import Topbar from "../Topbar/Topbar";
import Navbar from "../Navbar/Navbar";
import {
  FaHtml5,
  FaCss3Alt,
  FaJsSquare,
  FaGithub,
  FaReact,
  FaVuejs,
  FaBootstrap,
} from "react-icons/fa";
import {SiCsharp} from "react-icons/si";


const Skills =()=> {


       return (
        <div className={style.body}>
         <div className={style.wrapper}>
           <Topbar />
           <div className={style.imgarea}>
            <div className={style.iconContainer}>
               <div className={style.iconBox}>
                 <FaHtml5 className={`${style.icon} ${style.html}`} />
               </div>
             </div>
             <div className={style.iconContainer}>
               <div className={style.iconBox}>
                 <FaCss3Alt className={`${style.icon} ${style.css}`} />
               </div>
             </div>
             <div className={style.iconContainer}>
               <div className={style.iconBox}>
                 <FaJsSquare className={`${style.icon} ${style.js}`} />
               </div>
             </div>
             <div className={style.iconContainer}>
               <div className={style.iconBox}>
                 <FaGithub className={`${style.icon} ${style.github}`} />
               </div>
             </div>
             <div className={style.textContainer}>
               <span className={style.span}> Skills: </span>
             </div>
             <div className={style.iconContainer}>
               <div className={style.iconBox}>
                 <FaReact className={`${style.icon} ${style.react}`} />
               </div>
             </div>
             <div className={style.iconContainer}>
               <div className={style.iconBox}>
                 <FaVuejs className={`${style.icon} ${style.vue}`} />
               </div>
             </div>
             <div className={style.iconContainer}>
               <div className={style.iconBox}>
                 <SiCsharp className={`${style.icon} ${style.csharp}`} />
               </div>
             </div>
             <div className={style.iconContainer}>
               <div className={style.iconBox}>
                 <FaBootstrap className={`${style.icon} ${style.bootstrap}`} />
               </div>
             </div>
           </div>
           <div></div>
           <Navbar />
         </div>
         </div>
       );
}
export default Skills;