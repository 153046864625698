import React, {useState, useEffect} from "react";
import me from "../../Img/me.jpg";
import * as style from "./About.module.css";
import Topbar from "../Topbar/Topbar";
import Navbar from "../Navbar/Navbar.jsx";
import { FaLinkedin } from "react-icons/fa";


const About =()=> {

      const Resume = () => {
        window.open(
          "https://sararados.github.io/CV.english/",
          "_blank"
        );
      };

    return (
      <div className={style.body}>
      <div className={style.wrapper}>
        <Topbar />
        <div className={style.imgarea}>
          <span className={style.span}> Sara Rados</span>
          <img
            src={me}
            alt="Sara Rados"
            style={{ width: "270px", height: "270px" }}
          ></img>
          <p className={style.p}>
            "I recently graduated in Web Development.NET and possess skills in
            JavaScript, HTML, CSS, C#, .NET, React, and Git. My true passion
            lies in Frontend development, where I am dedicated to crafting
            user-friendly and visually captivating websites and applications.
            <br></br>
            <br></br>
            Beyond my technical expertise, I also have a deep appreciation for
            art and creativity. Drawing and crafting are personal passions of
            mine. By blending my artistic sensibilities with my web development
            skills, I have the ability to design visually stunning and
            highly functional websites."
          </p>
          <button className={style.button} onClick={Resume}>
            My CV
          </button>
        </div>
        <Navbar />
      </div>
    </div>
    );
}
export default About;
