.chatInfo {
    height: 50px;
    background-color: var(--lightblack);
    border-bottom: 2px solid var(--white);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    color: var(--white);
}
.span {
    color: var(--white);
    margin-top: -5px;
}
.chatIcons {
    display: flex;
    gap: 15px;
    cursor: pointer;
}
.Add {
    height: 25px;
    width: 25px;
} 
.more {
    margin-top: 5px;
}

.chat .messages {
 padding: 10px;
 height: calc(100% - 160px);
 overflow: scroll;
}

/* Uppdaterade stilar för att placera inmatningsfältet längst ner i chatten */

/* ... Dina tidigare stilar ... */

.chat {
    flex: 2;
    background-color: transparent;
    height: 80vh;
    display: flex;
    flex-direction: column;
    /* Lägg till detta för att använda en kolumnlayout */
}

.messages {
    overflow: scroll;
}




