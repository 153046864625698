import React from "react";
import { HashRouter, Routes, Route } from "react-router-dom";
import "./index.jsx";
import Home from './Components/Home/Home.jsx'
import Skills from "./Components/Skills/Skills.jsx";
import About from "./Components/About/About.jsx";
import ProjectList from "./Components/ProjectList/ProjectList.jsx";
import Contact from "./Components/Contact/Contact.jsx";
import Register from "./ChatApp/Register.jsx";
import Login from "./ChatApp/Login.jsx";
import ChatHome from "./ChatApp/ChatHome.jsx";



export const App = () => {
  return (
    <div className="App">
      <HashRouter>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/Skills" element={<Skills />}></Route>
          <Route path="/About" element={<About />}></Route>
          <Route path="/ProjectList" element={<ProjectList />}></Route>
          <Route path="/Contact" element={<Contact />}></Route>
          <Route path="/Register" element={<Register />}></Route>
          <Route path="/Login" element={<Login />}></Route>
          <Route path="/ChatHome" element={<ChatHome />}></Route>
        </Routes>
      </HashRouter>
    </div>
  );
};
