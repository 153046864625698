.input {
    height: 50px;
    background-color: var(--white);
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: auto;
    flex-wrap: wrap;
}
.send {
    display: flex;
    align-items: center;
    gap: 5px;
    flex-wrap: wrap;
}

.button {
    border: none;
    padding: 8px 13px;
    color: white;
    background-color: var(--lightblack);
    cursor: pointer;
    color: var(--white);
    border-radius: 5px;
    font-size: 18px;
}
.messageInput {
    width: 100%;
    border:none;
    outline:none;
    box-shadow: none;
    font-size: 18px;
    margin-left: 10px;

        /* Add margin-top for spacing */
}
.icon {
    font-size: 45px;
    cursor:pointer;
    color: var(--lightblack);
    margin-left: 45px;
}
.icon2 {
    font-size: 30px;
    margin-right: 10px;
}

/* Responsive styles for small screens */
@media (max-width: 768px) {


}