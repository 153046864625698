import React from 'react';
import style from './ChatHome.module.css';
import Sidebar from './Sidebar.jsx';
import Chat from "./Chat.jsx";

const ChatHome = () => {

    return (
      <div className={style.wrapper}>
        <div
          className={style.container}
          style={{ width: "65%", height: "80%" }}
        >
          <div className={style.sidebar}>
            <Sidebar />
          </div>
          <div className={style.chat}>
          <Chat  />
          </div>
        </div>
      </div>
    );
}
export default ChatHome;