import React from 'react';
import style from './Chat.module.css';
import {TfiMoreAlt} from 'react-icons/tfi';
import {RiUserAddLine} from 'react-icons/ri';
import Messages from './Messages.jsx';
import Input from './Input.jsx';

const Chat = () => {
  return (
    <div className={style.chat}>
      <div className={style.chatInfo}>
        <span className={style.span}> Sara </span>
        <div className={style.chatIcons}>
          <RiUserAddLine className={style.Add} />
          <TfiMoreAlt className={style.more} />
        </div>
      </div>
      <Messages className={style.messages} />
      <Input className={style.input} />
    </div>
  );
}

export default Chat