
.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(var(--pink) 0%, var(--violet) 100%);
}

.container {
    display: flex;
    width: 65%;
    height: 80%;
    border: 1px solid var(--white);
    border-radius: 8px;
    overflow: hidden;
}

.sidebar {
    flex: 1;
    background-color: var(--lightblack);
    position: relative;
    border-right: 2px solid white;
}

.chat {
    flex: 2;
    background-color: transparent;
    height: 80vh;
}

/* Responsive styles for small screens */
@media (max-width: 768px) {



}

