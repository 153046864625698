import React, {useState, useEffect} from "react";
import * as style from "./Home.module.css";
import selfie from '../../Img/selfie.png'
import Topbar from "../Topbar/Topbar";
import Navbar from "../Navbar/Navbar.jsx";
import {
  FaLinkedin,
  FaFacebookSquare,
  FaInstagramSquare,
} from "react-icons/fa";

const Home =()=> {

    return (
      <div className={style.body}>
        <div className={style.wrapper}>
          <Topbar />
          <div className={style.imgarea}>
            <img
              src={selfie}
              alt="Sara Rados"
              style={{ width: "", height: "350px" }}
            ></img>
          </div>

          <div className={style.songdetails}>
            <p> Hi! </p>
            <p>
              My name is Sara and welcome to my Portfolio. Feel free to look
              around!
            </p>
            <a
              href="https://www.linkedin.com/in/sara-rados-b84864230"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaLinkedin className={`${style.icon} ${style.linkedin}`} />
            </a>
            <a
              href="https://www.facebook.com/sara.rados.77/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaFacebookSquare className={`${style.icon} ${style.facebook}`} />
            </a>
            <a
              href="https://www.instagram.com/sarar_drawings/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagramSquare
                className={`${style.icon} ${style.instagram}`}
              />
            </a>
          </div>

          <Navbar />
        </div>
      </div>
    );
}
export default Home;